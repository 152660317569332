import React, { useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core";
import NavBar from "./NavBar";
import TopBar from "./TopBar";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    display: "flex",
    height: "100%",
    overflow: "hidden",
    width: "100%",
  },
  wrapper: {
    backgroundColor:"#222222",
    display: "flex",
    flex: "1 1 auto",
    overflow: "hidden",
    paddingTop: 70,
    minHeight: "calc(100vh - 80px)",
    [theme.breakpoints.up("lg")]: {
      padding:"2.5rem",
    },
    "@media (max-width: 1280px)": {
      padding:"2.5rem 3.1rem",
      // backgroundColor:"red",
     },
  },
  contentContainer: {
    flex: "1 1 auto",
    overflow: "hidden",
    background: "white",
    marginLeft:"3.8rem",
    borderRadius: "5rem",
    "@media (max-width: 1662px)": {
      marginLeft:'2.6rem',
    },
  },
  content: {
    
    flex: "1 1 auto",
    height: "100%",
    width: "100%",
    position: "relative",
    WebkitOverflowScrolling: "touch",
  },
}));

const DashboardLayout = ({ children }) => {
  const classes = useStyles();
  const [isMobileNavOpen, setMobileNavOpen] = useState(false);

  return (
    <div className={classes.root}>

      <NavBar
        onMobileClose={() => setMobileNavOpen(false)}
        openMobile={isMobileNavOpen}
      />
      <div className={classes.wrapper} >
        <div className={classes.contentContainer}>
        <TopBar onMobileNavOpen={() => setMobileNavOpen(true)} />
          <div className={classes.content} id="main-scroll">
            {children}
          </div>
        </div>
      </div>
    </div>
  );
};

DashboardLayout.propTypes = {
  children: PropTypes.node,
};

export default DashboardLayout;
