import React from "react";
import { makeStyles, Grid } from "@material-ui/core";
import { Height } from "@material-ui/icons";



const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#202020",
    color:"#fff",
    padding:"1.8rem",
    paddingBottom: "60px",
    height:"100%",
    "@media (min-width: 1326px)": {
      "& .MuiContainer-root":{
        paddingLeft:"0",
        paddingRight:"0",
      }
    },
  },
  MainLayout: {
    // backgroundColor: "red",
    // margin: "10rem 10rem 0rem 10rem",
    
    padding: "10rem 10rem 0rem 10rem",
    paddingBottom:"4.4rem",
    // paddingTop:'1rem',
    height: "100%",
    textAlign: "center",

    "@media (max-width: 1662px)": {
      padding: "0rem 5rem",
      // backgroundColor:"red"
     },
   
  },
 
}));

const MainLayout = ({ children }) => {
  const classes = useStyles();

  return (
    <Grid className={classes.root}>
      <Grid style={{ backgroundColor: "#202020",}}></Grid>
      <Grid className={classes.MainLayout}>{children}</Grid>
      <Grid style={{ backgroundColor: "#202020",padding:"1.25rem"}}></Grid>
    </Grid>
  );
};

export default MainLayout;
