import React, { useState } from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import {
  AppBar,
  Toolbar,
  makeStyles,
  IconButton,
  Hidden,
  SvgIcon,
  Box,
  Typography, InputBase,

} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";

import { Menu as MenuIcon } from "react-feather";

import { Dialog } from "@material-ui/core";

import { alpha } from "@material-ui/core/styles";

import { useHistory } from "react-router-dom";

import RightSideDrawer from "./RIghtSideDrawer";
import { IoNotificationsOutline } from "react-icons/io5";
import { useLocation } from "react-router-dom";
const useStyles = makeStyles((theme) => ({
  root: {
    // backgroundColor: theme.palette.secondary.main,
    position: "relative !important",
  },
  desktopDrawer1: {
    position: "absolute",
    right: 73,
    top: 0,
    width: "240px !important",
    padding: "12px",
    color: "#000",
  },
  toolbar: {
    padding: "0 10px",
    float: "left",
    width: "calc(100% - 20px)",

    display: "flex",
    justifyContent: "space-between",

    padding: 0,
    //background: "#fff",
    "@media (max-width: 1279px)": {
      width: "100%",
    },
  },
  search: {
    position: "relative",
    borderRadius: "100px",
    height: '55px',
    width: '28px',
    
    backgroundColor: "#F2F5FA",
    "&:hover": {
      backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),

    width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(3),
      width: "auto",
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: "100%",
    //position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    opacity: "0.8",
    borderRadius:'0px'
  },
  inputRoot: {
    color: "inherit",
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    border: "0.5px solid #DBD8FC",
    borderRadius: "70px",
    width: "400px !important",
    backgroundColor:"#F2F5FA",
    opacity: "0.9",
    boxShadow:
      "0px 100px 80px rgba(41, 72, 152, 0.05), 0px 64.8148px 46.8519px rgba(41, 72, 152, 0.037963), 0px 38.5185px 25.4815px rgba(41, 72, 152, 0.0303704), 0px 20px 13px rgba(41, 72, 152, 0.025), 0px 8.14815px 6.51852px rgba(41, 72, 152, 0.0196296), 0px 1.85185px 3.14815px rgba(41, 72, 152, 0.012037)",
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create("width"),
    width: "0%",
    [theme.breakpoints.up("md")]: {
      //width: "61ch",
    },
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 220,
  },
  logo: {
    marginRight: theme.spacing(2),
  },
  link: {
    fontWeight: theme.typography.fontWeightMedium,
    "& + &": {
      marginLeft: theme.spacing(2),
    },
  },
  divider: {
    width: 1,
    height: 32,
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
  },

  FiterBox: {
    [theme.breakpoints.only("xs")]: {
      display: "none",
    },
  },
  icon: {
    fontSize: "25px",
    padding: "0px 20px",
    cursor: "pointer", height: "55px", borderRadius: '100px', background:"#F2F5FA", width:'19px'
  },
  input: {
    borderRadius: "7px",
    background: "#fff",
  },
  title: {
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: "32px",
    lineHeight: "48px",
    letterSpacing: "0.02em",
    color: "#000000",
  },
}));

const TopBar = ({ className, onMobileNavOpen, ...rest }) => {
  const location = useLocation();
  const classes = useStyles();
  const history = useHistory();
  const [rightBar, setRightBar] = useState(false);
  const [state, setState] = React.useState({
    Deposite: "",
    name: "",
  });
  console.log(location, "location");

  const femmecubatorLogo = (
    <div className={classes.search}>
      <div className={classes.searchIcon}>
        <SearchIcon style={{ color:"#020B2A" }} />
      </div>
      {/*<InputBase
        placeholder=""
        classes={{
          root: classes.inputRoot,
          input: classes.inputInput,
        }}
        inputProps={{ 'aria-label': 'search' }}
      />*/}
    </div>
  );

  return (
    <AppBar elevation={0} className={classes.root} color="inherit" {...rest}>
      <Toolbar className={classes.toolbar}>
        <Hidden lgUp>
          <IconButton
            color="#fff"
            onClick={onMobileNavOpen}
            style={{ marginRight: 10, padding: "23px" }}
          >
            <SvgIcon fontSize="small">
              <MenuIcon style={{ color: "#197ab3" }} />
            </SvgIcon>
          </IconButton>
        </Hidden>

        <Box style={{ marginLeft: "2rem" }}>
          <p className={classes.title}>
            {location.pathname == "/dash"
              ? "Overview"
              : location.pathname == "/transaction-history" || location.pathname == "/fav"
              ? "Transaction History"
              : location.pathname == "/settings"
              ? "Settings"
              : location.pathname == "/wallet"
              ? "Wallet"
              : ""
            }
          </p>
        </Box>
        <Box
          style={{ display: "flex", alignItems: "center", marginRight: "7rem" }}
        >
          <div style={{width:'min-content'} }>{femmecubatorLogo}</div>
          <Box className={classes.FiterBox}>
            <Box style={{ display: "flex", alignItems: "center" }}>
              <Box></Box>
              <Box>
                <IoNotificationsOutline
                  className={classes.icon}
                  onClick={() => history.push("/notification")}
                />
              </Box>
              <Dialog
                style={{ width: "200px !important" }}
                classes={{ paper: classes.desktopDrawer1 }}
                open={rightBar}
                onClose={() => {
                  setRightBar(false);
                }}
              >
                <Typography style={{ color: "#000" }}>Notification</Typography>
              </Dialog>
            </Box>
          </Box>
          &nbsp;&nbsp;
          <RightSideDrawer />
        </Box>
      </Toolbar>
    </AppBar>
  );
};

TopBar.propTypes = {
  className: PropTypes.string,
};
TopBar.defaultProps = {
  onMobileNavOpen: () => {},
};

export default TopBar;
