import React, { lazy } from "react";
import { Redirect } from "react-router-dom";
import DashboardLayout from "src/layouts/DashboardLayout1";
import HomeLayout from "src/layouts/HomeLayout";
// import Memorize from "src/views/pages/RecoveryPhrase/memorize.js";
// import MainGrid from "./views/pages/Wallet";

export const routes = [
  {
    exact: true,
    path: "/",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/Welcome")),

  },
  {
    exact: true,
    path: "/password",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/Password")),

  },
  {
    exact: true,
    path: "/recovery",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/RecoveryPhrase")),

  },
  {
    exact: true,
    path: "/memorize",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/RecoveryPhrase/memorize.js")),

  },
  {
    exact: true,
    path: "/arrange",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/RecoveryPhrase/arrange")),

  },
  {
    exact: true,
    path: "/dash",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard")),

  },
  {
    exact: true,
    path: "/wallet",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Wallet")),

  },
  {
    exact: true,
    path: "/transaction-history",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/transactionHistory")),

  },
  {
    exact: true,
    path: "/fav",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/transactionHistory/favourites")),

  },
  {
    exact: true,
    path: "/settings",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Settings")),

  },
  {
    exact: true,
    path: "/404",
    component: lazy(() => import("src/views/errors/NotFound")),
  },
  {
    component: () => <Redirect to="/404" />,
  },
];
